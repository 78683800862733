import React from 'react';
import styled from 'styled-components';



function Links() {
  return (
    <StyledLinks>
        <div>
            <div>
                <a href="https://github.com/LatteDunker" rel="noreferrer" target="_blank">GitHub</a>
            </div>
            <div>
            <a href="https://www.linkedin.com/in/alyaj/" rel="noreferrer" target="_blank">LinkedIn</a>
            </div>
        </div>
    </StyledLinks>
  );
}

export default Links;

const StyledLinks = styled.div`
a {
  text-decoration: none;  /* Removes underline */
  color: inherit;         /* Inherits the text color from the parent element */
}

a:visited {
  color: inherit;         /* Ensures visited links have the same color as unvisited links */
}

a:hover {
    text-decoration: underline;
}

a:active {
  color: inherit;         /* Ensures active links have the same color */
}
`