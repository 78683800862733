/*import { React, useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { AsciiRenderer } from '@react-three/drei'

function Torusknot(props) {
  const ref = useRef()
  const viewport = useThree((state) => state.viewport)
  useFrame((state, delta) => (ref.current.rotation.x = ref.current.rotation.y += delta / 2))
  return (
    <mesh scale={Math.min(viewport.width, viewport.height) / 5} {...props} ref={ref}>
      <torusKnotGeometry args={[1, 0.2, 128, 32]} />
      <meshStandardMaterial color="red" />
    </mesh>
  )
}

const Cube = () => {
  return (
    <div style={{ width: '500px', height: '300px' }}>
      <Canvas className="canvas">
        <color attach="background" args={['black']} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <Torusknot />
        <AsciiRenderer fgColor="black" bgColor="transparent" />
      </Canvas>
    </div>
  );
}

export default Cube;
*/

import React, { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { AsciiRenderer } from '@react-three/drei'
import * as THREE from 'three';

const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

const SpinningCube = () => {
  const groupRef = useRef(null);
  const meshRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  

  
  useFrame(() => {
    if (groupRef.current) {
      // groupRef.current.rotation.x += 0.01;
       groupRef.current.rotation.y += 0.01;
      // groupRef.current.rotation.z += 0.01;
    }
    /* if (meshRef.current) {
      // meshRef.current.rotation.x += 0.01;
      //meshRef.current.rotation.y += 0.01;
      //meshRef.current.rotation.z += 0.01;
    }*/
  });

  const materials = [
    new THREE.MeshBasicMaterial({ color: 0xff0000 }), // red
    new THREE.MeshBasicMaterial({ color: 0x00ff00 }), // green
    new THREE.MeshBasicMaterial({ color: 0x0000ff }), // blue
    new THREE.MeshBasicMaterial({ color: 0x0000ff }), // blue
    new THREE.MeshBasicMaterial({ color: 0x00ffff }), // cyan
    new THREE.MeshBasicMaterial({ color: 0xff00ff })  // magenta
  ];

  return (
    <group ref={groupRef}
    position={[0, 0, 0]} // Initial position

    >

    <mesh
      ref={meshRef}
      position={[0, 0, 0]} // Initial position
      rotation={[
        degreesToRadians(45), // Initial rotation x
        degreesToRadians(45), // Initial rotation y
        degreesToRadians(0)   // Initial rotation z
      ]}
      scale={2}
      material={materials}
    >
      <boxGeometry args={[1, 1, 1]} />
    </mesh>
  </group>
  );
};

const Cube = () => {
  return (
    <Canvas>
      <color attach="background" args={['black']} />
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <SpinningCube />
      <AsciiRenderer fgColor="black" bgColor="transparent" resolution={0.2}/>

    </Canvas>
  );
};
//       <AsciiRenderer fgColor="black" bgColor="transparent" />

export default Cube;