import React from 'react';
import './App.css';
import Cube from './Cube.js'
import Links from './Links.js'


const App = () => {
  return (
    <div className="app">
      <div className="centered-component">
        <h1>alyajouri II</h1>
        <p>My tiny corner of the web.</p>
        <Cube />
        <Links />
      </div>
    </div>
  );
}

export default App;